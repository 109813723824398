// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ftco-section.login {
    background-color: #f9f9f9;
    padding: 60px 0;
    
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    width : 100%;
 }
 
 .box-login {
    padding: 40px;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
    width : 100%;
 }
 
 .box-login:hover {
    transform: scale(1.05);
    width : 100%;
 }
 
 .form-control-login {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 5px;
    transition: border-color 0.3s;
    width : 100%;
 }
 
 .form-control-login:focus {
    border-color: #007bff;
    outline: none;
    width : 100%;
 }
 `, "",{"version":3,"sources":["webpack://./src/css/ForgetPW.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,eAAe;;IAEf,sBAAsB;IACtB,2BAA2B;IAC3B,mBAAmB;IACnB,YAAY;CACf;;CAEA;IACG,aAAa;IACb,iBAAiB;IACjB,wCAAwC;IACxC,oBAAoB;IACpB,YAAY;CACf;;CAEA;IACG,sBAAsB;IACtB,YAAY;CACf;;CAEA;IACG,mBAAmB;IACnB,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,6BAA6B;IAC7B,YAAY;CACf;;CAEA;IACG,qBAAqB;IACrB,aAAa;IACb,YAAY;CACf","sourcesContent":[".ftco-section.login {\n    background-color: #f9f9f9;\n    padding: 60px 0;\n    \n    background-size: cover;\n    background-position: center;\n    border-radius: 10px;\n    width : 100%;\n }\n \n .box-login {\n    padding: 40px;\n    background: white;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    transition: all 0.3s;\n    width : 100%;\n }\n \n .box-login:hover {\n    transform: scale(1.05);\n    width : 100%;\n }\n \n .form-control-login {\n    margin-bottom: 20px;\n    border: 1px solid #ddd;\n    padding: 15px;\n    border-radius: 5px;\n    transition: border-color 0.3s;\n    width : 100%;\n }\n \n .form-control-login:focus {\n    border-color: #007bff;\n    outline: none;\n    width : 100%;\n }\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
